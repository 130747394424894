import { Controller } from "@hotwired/stimulus"
import { getExamplePlaceholder, parsePhone } from "../helpers"

const WPP_URL = "https://api.whatsapp.com/send?phone="

export default class extends Controller {
  static targets = [ "flag", "select", "input", "deleteButton" ]

  initialize() {
    this.updatePlaceholder()
  }

  changeCountry() {
    this.updatePlaceholder()
    const flag = new URL(`../images/flags/${this.countryCode.toLowerCase()}.svg`, import.meta.url).href
    this.flagTarget.src = flag
  }

  formatOnPaste(event) {
    event.preventDefault()
    const phone = event.clipboardData.getData('text')
    this.inputTarget.value += phone

    this.validateAndParse()
  }

  formatOnType() {
    this.validateAndParse()
  }

  submitForm(event) {
    const phone = parsePhone(this.inputValue, this.countryCode)
    if (phone) {
      open(WPP_URL + phone.e164.replace('+', ''), '_self')
    } else {
      event.preventDefault()
      this.inputTarget.focus()
    }
  }

  toggleDelete() {
    if (this.inputValue.length >= 6) {
      this.deleteButtonTarget.classList.remove("hidden")
    } else {
      this.deleteButtonTarget.classList.add("hidden")
    }
  }

  resetInput() {
    this.inputTarget.value = ""
    this.inputTarget.focus()
  }

  // Private

  updatePlaceholder() {
    this.inputTarget.placeholder = getExamplePlaceholder(this.countryCode)
  }

  updateForm({ phone, country }) {
    this.inputTarget.value = phone
    this.selectTarget.value = country
    this.changeCountry()
  }

  validateAndParse() {
    if (this.inputValue.length > 6 && this.inputValue[0] === "+") {
      const result = parsePhone(this.inputValue)
      if (result?.country) return this.updateForm(result)
    }
  }

  get countryCode() {
    return this.selectTarget.value
  }

  get inputValue() {
    return this.inputTarget.value
  }
}
