import '~/styles/application.css'
import "~/controllers"

import Rails from '@rails/ujs'

if (!window._rails_loaded) Rails.start()

//register service worker
if(navigator.serviceWorker){
  navigator.serviceWorker.register('/sw.js')
}
