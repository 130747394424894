import examples from 'libphonenumber-js/examples.mobile.json'
import {
  getExampleNumber,
  parsePhoneNumberFromString
} from 'libphonenumber-js'

export function getExamplePlaceholder(countryCode) {
  const phone = getExampleNumber(countryCode, examples)
  return phone?.formatNational() || "(321) 123-4567"
}

export function parsePhone(phoneNumber, countryCode = null) {
  const phone = parsePhoneNumberFromString(phoneNumber, countryCode)
  if (phone) {
    return {
      phone: phone.formatNational(),
      country: phone.country,
      e164: phone.number
    }
  }
}
